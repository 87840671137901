import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import { TwoColumnPropertiesType } from '../../../../types/CategoryAppType';
import { NAFGTMEvent } from '../../../../types/GTM/NAFGTMEvent';
import { SectionBlocks } from '../../components/SectionBlocks';
import { useGTMDataLayer } from '../../hooks/GTM/useGTMDataLayer';
import useSelector from '../../redux/typedHooks';
import ErrorBoundary from '../../components/error-component/ErrorBoundary';

type TabContainerProps = {
  tabs: TwoColumnPropertiesType['tabs'];
  isLeftColumn: boolean;
  onTabChange?: (tabTitle?: string) => void;
  tabTitle?: string | null;
};

function firstVisibleIndexOrZero(tabs: TabContainerProps['tabs'], isLeftColumn: boolean) {
  // In the right column, there are no hidden tabs so 0 is always the first one visible
  if (!isLeftColumn) {
    return 0;
  }

  // In the left column, we have to search for the first visible tab in case 0 is hidden
  const index = tabs.findIndex((tab) => tab.column === 'left');

  return index === -1 ? 0 : index;
}

function getTabIndex(tabs: TabContainerProps['tabs'], isLeftColumn: boolean, title?: string | null) {
  if (title) {
    const index = tabs.findIndex((tab) => tab.title === title);
    return index === -1 ? firstVisibleIndexOrZero(tabs, isLeftColumn) : index;
  }

  return firstVisibleIndexOrZero(tabs, isLeftColumn);
}

const TabContainer = ({ tabs, isLeftColumn, tabTitle, onTabChange }: TabContainerProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(getTabIndex(tabs, isLeftColumn, tabTitle));

  const dataLayer = useGTMDataLayer();
  const location = useLocation();
  const baseUrl = useSelector((state) => state.application.baseUrl);

  const handleHeaderClick = (e: React.MouseEvent<HTMLButtonElement>, tabIndex: number) => {
    e.preventDefault();

    if (dataLayer) {
      dataLayer.push({
        event: NAFGTMEvent.tabNavigation,
        tabNavigation: {
          from: tabs[selectedTabIndex]?.title || 'UNDEFINED',
          to: tabs[tabIndex]?.title || 'ERROR',
        },
        page: location.pathname,
        pageUrl: `${baseUrl}${location.pathname}`,
      });
    }

    onTabChange?.(tabs[tabIndex]?.title);
    setSelectedTabIndex(tabIndex);
  };

  // Rerender if tab list or selected tab title changes and that makes
  // a difference to the index
  useEffect(() => {
    const newIndex = getTabIndex(tabs, isLeftColumn, tabTitle);
    if (newIndex !== selectedTabIndex) {
      setSelectedTabIndex(newIndex);
    }
  }, [tabs, tabTitle, isLeftColumn, selectedTabIndex]);

  return (
    <div>
      <ScrollContainer>
        <HeaderBorder>
          <TabHeader role="tablist">
            {tabs.map((tab, tabIndex) =>
              tab.column === 'right' ? (
                <RightTabHeaderButton
                  key={tab.title}
                  $isLeftColumn={isLeftColumn}
                  $active={selectedTabIndex === tabIndex}
                  type="button"
                  onClick={(e) => handleHeaderClick(e, tabIndex)}
                  role="tab"
                  aria-selected={selectedTabIndex === tabIndex}
                  aria-controls={tab.title}
                  id={`tab-${isLeftColumn ? 'left' : 'right'}-${tab.title}`}
                >
                  {tab.title}
                </RightTabHeaderButton>
              ) : (
                <BaseTabHeaderButton
                  key={tab.title}
                  $active={selectedTabIndex === tabIndex}
                  type="button"
                  onClick={(e) => handleHeaderClick(e, tabIndex)}
                  role="tab"
                  aria-selected={selectedTabIndex === tabIndex}
                  aria-controls={tab.title}
                  id={`tab-${isLeftColumn ? 'left' : 'right'}-${tab.title}`}
                >
                  {tab.title}
                </BaseTabHeaderButton>
              ),
            )}
          </TabHeader>
        </HeaderBorder>
      </ScrollContainer>
      <ErrorBoundary>
        <SectionContainer
          role="tabpanel"
          aria-labelledby={`tab-${isLeftColumn ? 'left' : 'right'}-${tabs[selectedTabIndex]?.title}`}
        >
          <SectionBlocks content={tabs[selectedTabIndex]?.content || []} />
        </SectionContainer>
      </ErrorBoundary>
    </div>
  );
};

const BaseTabHeaderButton = styled.button<{ $active: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: transparent;
  cursor: pointer;
  border: 2px solid transparent;
  border-bottom: 3px solid transparent;
  color: ${nafColor.neutral.neutral5};

  flex-shrink: 0;
  flex-basis: max-content;

  &:hover {
    color: ${nafColor.signature.black};
    border-bottom-color: ${nafColor.neutral.neutral5};
    outline: 0;
    letter-spacing: inherit;
  }

  ${({ $active }) =>
    $active
      ? css`
          color: ${nafColor.signature.black};
          font-weight: 600;
          border-bottom-color: ${nafColor.primary.kart};
          letter-spacing: inherit;
        `
      : ''}

  &:focus:not(:disabled)[type='button'] {
    color: ${nafColor.signature.black};
    font-weight: 600;
    border-color: ${nafColor.neutral.neutral5};
    border-radius: 2px;
    letter-spacing: inherit;
    outline: none;
  }
`;

const RightTabHeaderButton = styled(BaseTabHeaderButton)<{ $isLeftColumn: boolean; $active: boolean }>`
  display: ${({ $isLeftColumn, $active }) => ($isLeftColumn && !$active ? 'none' : 'initial')};

  @media (max-width: calc(${breakpoints.xl} + 9px)) {
    display: initial;
  }
`;

const TabHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow-y: hidden;
  column-gap: ${spacing.space24};

  height: 50px;
  margin-bottom: -1px;
`;

const HeaderBorder = styled.div`
  height: 49px;
  border-bottom: 1px solid ${nafColor.neutral.neutral2};

  min-width: max-content;
  width: 100%;
`;

const ScrollContainer = styled.div`
  display: flex;
  margin: 0 -${spacing.space40};
  padding: 0 ${spacing.space40};
  box-sizing: border-box;

  overflow-x: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${breakpoints.m}) {
    width: 100vw;

    margin: 0 -${spacing.space24};
    padding: 0 ${spacing.space24};
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: ${spacing.space80};

  margin: ${spacing.space40} 0 ${spacing.space64} 0;
`;

export { TabContainer };
