import { Adjust } from '@cloudinary/url-gen/actions/adjust';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { spacing, breakpoints } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { TwoColumnAppType, TwoColumnPropertiesType } from '../../../../types/CategoryAppType';
import { SiteStructureType } from '../../../../types/siteStructureType';
import FeedbackSurvey from '../../components/feedbacksurvey/FeedbackSurvey';
import { Layout } from '../../components/layout/Layout';
import { AccordionBlockWrapper } from '../../components/SectionBlocks/blocks/AccordionBlock';
import { Container as IframeBlockContainer } from '../../components/SectionBlocks/blocks/IframeBlock';
import { LivestreamBlockWrapper } from '../../components/SectionBlocks/blocks/LivestreamBlock';
import { HeadContainer as NewsfeedHeaderContainer } from '../../components/SectionBlocks/blocks/NewsfeedBlock';
import { HeaderContainer as QnaHeaderContainer } from '../../components/SectionBlocks/blocks/QnaFeedBlock/Header';
import { TextBlockContainer } from '../../components/SectionBlocks/blocks/TextBlock';
import { ChartWrapper } from '../../components/SectionBlocks/Testshowcase/Results';
import { ShowcaseBlockWrapper } from '../../components/SectionBlocks/Testshowcase/ShowcaseBlock';
import { useCloudinary } from '../../hooks/Cloudinary/useCloudinary';
import { useDevicePixelRatio } from '../../hooks/useDevicePixelRatio';
import { useWindowLocation } from '../../hooks/Window/useWindowLocation';
import { HeroContent } from './HeroContent';
import { TabContainer } from './TabContainer';
import { BlockHeader as ColumnBlockHeader } from '../../components/SectionBlocks/blocks/MultiRowArticleBlock';
import { ColumnWrapper, IngressText as ColumnBlockIngressText } from '../../components/SectionBlocks/ColumnBlock';
import ErrorBoundary from '../../components/error-component/ErrorBoundary';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';

export const TwoColumnApp = ({ data, appData }: { data: SiteStructureType; appData: TwoColumnAppType }) => {
  const { heroImage, heroContent, darkenBackground, linkPreview, tabs } = appData.properties;

  // Handle query string for linking into specific tabs
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const updateSearchParams = (key: string, value: string | undefined) => {
    if (value) {
      searchParams.set(key, value);
      history.push({ search: searchParams.toString() });
    }
  };

  // Extract tabs for the right column
  const [rightColumnTabs, setRightColumnTabs] = useState<TwoColumnPropertiesType['tabs']>(
    tabs.filter((tab) => tab.column === 'right'),
  );
  useEffect(() => setRightColumnTabs(tabs.filter((tab) => tab.column === 'right')), [tabs]);

  // Fetch hero background image
  const contentUrl = useWindowLocation();
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();

  const backgroundImage = heroImage
    ? cld.image(heroImage.publicId).delivery(dpr(devicePixelRatio)).quality('auto:good').format('auto')
    : null;
  if (backgroundImage && darkenBackground) {
    backgroundImage.adjust(Adjust.opacity(60)).backgroundColor('black');
  }

  // Memoise hero to prevent page bouncing on rerender
  const Hero = useMemo(() => <HeroContent heroContent={heroContent} />, [heroContent]);

  return (
    <Layout
      title={data.seoConfig?.title || data.name}
      description={data.seoConfig?.introduction || data.ingress || ''}
      gtmArgs={{ page_type: 'TwoColumnApp', pageCategory: data.slug, contentId: data.name }}
      imgUrl={linkPreview?.srcSet?.jpgLarge || undefined}
      internalSearchMetaData={{ cardType: InternalSearchCardType.CategoryApp }}
    >
      <PageContent $backgroundSrc={backgroundImage?.toURL()}>
        {Hero}
        <ColumnContainer>
          <LeftColumn>
            <ErrorBoundary>
              <TabContainer
                tabs={tabs}
                isLeftColumn
                tabTitle={searchParams.get('leftColumn')}
                onTabChange={(tabTitle) => updateSearchParams('leftColumn', tabTitle)}
              />
            </ErrorBoundary>
          </LeftColumn>
          <RightColumn>
            <ErrorBoundary>
              <TabContainer
                tabs={rightColumnTabs}
                isLeftColumn={false}
                tabTitle={searchParams.get('rightColumn')}
                onTabChange={(tabTitle) => updateSearchParams('rightColumn', tabTitle)}
              />
            </ErrorBoundary>
          </RightColumn>
        </ColumnContainer>
        <FeedbackContainer>
          <FeedbackSurvey contentUrl={contentUrl} />
        </FeedbackContainer>
      </PageContent>
    </Layout>
  );
};

const LeftColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;

  flex: 2 0 calc(66% - 2 / 3 * ${spacing.space48});

  @media (max-width: calc(${breakpoints.xl} + 9px)) {
    flex-basis: 100%;
  }

  @media (min-width: calc(${breakpoints.m} + 1px)) {
    ${ShowcaseBlockWrapper},
    ${LivestreamBlockWrapper}, ${NewsfeedHeaderContainer}, ${QnaHeaderContainer}, ${IframeBlockContainer} {
      h1,
      h1 + p {
        max-width: 50ch;
      }
    }

    ${AccordionBlockWrapper} {
      h2,
      h2 + p {
        max-width: 50ch;
      }
    }

    ${ColumnBlockHeader} h2,
    ${ColumnBlockIngressText} {
      max-width: 60ch;
    }

    ${TextBlockContainer} {
      max-width: 75ch;
    }

    ${ChartWrapper} {
      h2,
      h2 + p {
        max-width: 50ch;
      }
    }
  }

  ${ColumnWrapper} {
    @media (max-width: ${breakpoints.m}) {
      width: calc(100vw - 48px);
      margin: 0 -${spacing.space24};
      padding: 0 ${spacing.space24};
    }
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;

  flex: 1 0 calc(33% - 1 / 3 * ${spacing.space48});

  @media (max-width: calc(${breakpoints.xl} + 9px)) {
    display: none;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  box-sizing: border-box;
  column-gap: ${spacing.space48};

  margin: 0 auto;
  min-height: 50vh;

  background-color: ${nafColor.signature.white};
  border-radius: ${spacing.space4};

  padding: ${spacing.space24} ${spacing.space40};

  @media (min-width: calc(${breakpoints.m} + 1px)) {
    width: 90%;
    max-width: 1600px;
  }

  @media (max-width: ${breakpoints.m}) {
    width: 100%;
    padding: ${spacing.space24};
  }
`;

const PageContent = styled.div<{ $backgroundSrc?: string }>`
  min-height: 100vh;

  ${({ $backgroundSrc }) => ($backgroundSrc ? `background-image: url('${$backgroundSrc}');` : '')}
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;

  @media (min-height: 69vw) {
    // Approximation of viewport aspect-ratio 1.45
    background-size: auto 80vh;
  }
`;

const FeedbackContainer = styled.div`
  margin: 0 auto;
  box-sizing: border-box;

  padding: 0 ${spacing.space40} ${spacing.space64} ${spacing.space40};

  @media (min-width: calc(${breakpoints.m} + 1px)) {
    width: 90%;
    max-width: 1600px;
  }

  @media (max-width: ${breakpoints.m}) {
    width: 100%;
    padding: 0 ${spacing.space24} ${spacing.space64} ${spacing.space24};
  }
`;
